import React from 'react'

import Title from '../atoms/title'
import Link from '../atoms/link'
import Button from '../atoms/button'
import BodyCopy from '../atoms/body-copy'
import Lists from '../atoms/lists'


const Advertorial = ({
  content
}) => {
  return (
    <div className='co-advertorial'>
      <div className='l-section'>
        <Title
            type='h1'
            title={content.section1.titleLevel1}
            classNames='co-advertorial__title'
        />
        <Link 
          url={content.section1.image.linkUrl}
          align='center'
          classNames='co-advertorial__image l-spacing__m-tb--40'
          type="image"
        >
          <img src={content.section1.image.src} alt={content.section1.image.alt} />
        </Link>
        <Title
            type='h5'
            title={content.section1.titleLevel3}
            align='left'
            color='dark'
            weight='bold'
            classNames='co-advertorial__title'
        />
        <BodyCopy
          copy={content.section1.bodyCopy}
          classNames='co-advertorial__copy'
        />

        <Button
          type='tertiary'
          url={content.section1.button.url}
          label={content.section1.button.label}
          id={content.section1.button.id}
          classNames='co-advertorial__button l-spacing__m-t--40 l-spacing__p-t--24'
          large
          align='center'
        />
      </div>

      <div className='l-section'>
        <Title
          type='h3'
          title={content.section2.titleLevel2}
          align='left'
          color='dark'
          weight='bold'
          classNames='co-advertorial__title'
        />

        <BodyCopy
          copy={content.section2.bodyCopy}
          classNames='co-advertorial__copy'
        />

        <Lists
          items={content.section2.list}
          classNames='co-advertorial__list'
        />

        <Title
            type='h5'
            title={content.section2.titleLevel3}
            align='left'
            color='dark'
            weight='bold'
            classNames='co-advertorial__title'
        />
      </div>


      <div className='l-section'>
        <Title
          type='h3'
          title={content.section3.titleLevel2}
          align='left'
          color='dark'
          weight='bold'
          classNames='co-advertorial__title'
        />

        <BodyCopy
          copy={content.section3.bodyCopy}
          classNames='co-advertorial__copy'
        />

        <Lists
          items={content.section3.list}
          classNames='co-advertorial__list'
        />

        <Link
          url={content.section3.image.linkUrl}
          align='center'
          classNames='co-advertorial__image l-spacing__m-tb--40'
          type="image"
        >
          <img src={content.section3.image.src} alt={content.section3.image.alt} />
        </Link>

        <BodyCopy
          copy={content.section3.bodyCopy2}
          classNames='co-advertorial__copy'
        />

        <Button
          type='tertiary'
          url={content.section3.button.url}
          label={content.section3.button.label}
          id={content.section3.button.id}
          classNames='co-advertorial__button l-spacing__m-t--40 l-spacing__p-t--24'
          large
          align='center'
        />
      </div>

    </div>
  )
}

export default Advertorial
